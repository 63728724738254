import './index.scss'

import React, { useRef, useEffect, useState } from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Img from 'gatsby-image'

import { Container, Icon } from '@components'
import Button from '../Button'
import useIntersection from '../../hooks/useIntersection'

const bem = new BEMHelper('hero')

const isPlaying = video =>
  video &&
  video.currentTime > 0 &&
  !video.paused &&
  !video.ended &&
  video.readyState > video.HAVE_CURRENT_DATA

// eslint-disable-next-line complexity
export default function Hero({
  title,
  preamble,
  image,
  imageSrc,
  action,
  actions,
  type,
  section,
  video,
  children,
  aspectRatio,
  ...props
}) {
  const [paused, setPaused] = useState(true)
  const [forcedPause, setForcedPaused] = useState(false)
  const videoRef = useRef()
  const onScreen = useIntersection(videoRef)
  const Node = section ? 'section' : 'header'
  const Title = section ? 'h2' : 'h1'

  useEffect(() => {
    const vid = videoRef.current
    if (vid) {
      if (onScreen && !isPlaying(vid)) {
        playVideo()
      } else if (!onScreen && isPlaying(vid)) {
        vid.pause()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen])

  const handlePlaying = () => {
    setPaused(false)
  }

  const handlePause = () => {
    setPaused(true)
  }

  const playVideo = () => {
    if (videoRef.current && !forcedPause && !isPlaying(video.current)) {
      videoRef.current.play()
    }
  }

  const videoClick = () => {
    if (!videoRef.current) {
      return
    }
    if (videoRef.current.paused && !isPlaying(video.current)) {
      setPaused(false)
      setForcedPaused(false)
      videoRef.current.play()
    } else if (isPlaying(video.current)) {
      videoRef.current.pause()
      setPaused(true)
      setForcedPaused(true)
    }
  }

  const actionList = action ? [action] : actions || null

  return (
    <Node {...bem('', type)}>
      <Container node="div" {...bem('container')} {...props}>
        <div {...bem('content')}>
          <Title {...bem('title')}>{title}</Title>
          {preamble && <p {...bem('preamble')}>{preamble}</p>}

          {actionList && actionList.length > 0 && (
            <div {...bem('actions')}>
              {actionList.map((action, index) => (
                <Button key={index} primary {...action} {...bem('action')} />
              ))}
            </div>
          )}
          {children}
        </div>

        {image && (
          <Img
            sizes={{ ...image.fluid, ...(aspectRatio ? { aspectRatio } : {}) }}
            {...bem('image')}
          />
        )}
        {video && (
          <div {...bem('video-wrapper')}>
            <button
              type="button"
              {...bem('play', { paused })}
              aria-label={paused ? 'Spill av' : 'Pause'}
              onClick={videoClick}
            >
              {paused && (
                <span {...bem('play-icon')}>
                  <Icon icon="play" />
                </span>
              )}
            </button>

            <video
              poster={video.poster}
              loop
              muted
              {...bem('video')}
              ref={videoRef}
              onPlaying={handlePlaying}
              onPause={handlePause}
              playsInline
            >
              {video.webm && <source src={video.webm} type="video/webm" />}
              {video.mp4 && <source src={video.mp4} type="video/mp4" />}
            </video>
          </div>
        )}
      </Container>
    </Node>
  )
}

Hero.propTypes = {
  title: T.oneOfType([T.string, T.node]).isRequired,
  preamble: T.any,
  image: T.object,
  imageSrc: T.object,
  action: T.object,
  actions: T.array,
  type: T.any,
  section: T.bool,
  video: T.object,
  children: T.any,
  aspectRatio: T.oneOfType([T.string, T.number]),
}
