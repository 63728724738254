/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'

export default function useIntersection(ref, options = {}) {
  const observer = useRef()
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(!(entry.intersectionRatio < 0.5))
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        ...options,
      },
    )
    if (ref && ref.current) {
      observer.current.observe(ref.current)

      return () => {
        if (observer.current) {
          observer.current.unobserve(ref.current)
        }
      }
    }
  }, [])

  return isIntersecting
}
